/* @import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@200&family=Poppins:wght@200&display=swap"); */
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  @font-face {
    font-family: "Montserrat";
    src: url(./Fonts/Montserrat-Regular.ttf)
  }
}

body {
  margin: 0;
  padding: 0;
  /* font-family: 'Montserrat', sans-serif; */
}
.indicator::before {
  content: '';
  box-shadow: #ffb700 0px 0px 20px;
}
.indicator::after {
  content: '';
}
.range{
  background: linear-gradient(90deg, #ffa500, #ffda46);
}
.menu-range{
  background: linear-gradient(90deg, #ffa500, #ffda46);
}
.preview-1{
  background: linear-gradient(90deg, #ffa500, #ffda46);
  box-shadow: #ffa500 -2px 2px 7px;
}
.preview-1:hover{
  box-shadow: #ffa500 2px 5px 7px;
}

.preview-2:hover{
  animation: bounce 0.3s ease-in-out infinite ;
}
.range{
  animation: full 1s ease-in-out ;
}
.project-card{
  border-color: #984208;
}

.primary-btn{
  width: 100%;
  background-color: #ffa500;
  background-size: 200% 100%;
  padding: 12px 0;
  border-radius: 25px;
  text-align: center;
  overflow: hidden;
  line-height: 28px;
  background-image: linear-gradient(to right, #e27c00 50%, #ffa500 50%   );
  color: white;
  cursor: pointer;
  background-position: 100% 0 ;
  transition: background-position 0.5s ease;
}
.primary-btn:hover{
  background-position: 0% 0;
}

@keyframes full {
  from{
    width: 0%;
  }
  to{
    width: 100%;
  }
}
@keyframes bounce {
  0%, 100% {
      transform: rotateZ(3deg);
  }
  50% {
      transform: rotateZ(-3deg);
  }
}